.App {
  margin: 0 0;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: black;
  color: white;
}

a{
  color: white;
  text-decoration: none;
}

a:hover{
  color: #FAFAFA;
}
